import { WindowRefService } from './WindowRef.service';
import { Inject, Injectable } from '@angular/core';
import { CodeValueGroups, PhxConstants, StateHistory, ValidationMessage } from '../model';
import { environment } from '../../../environments/environment';
import { ToastService } from './toast.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Moment } from 'moment';
import { saveAs } from 'file-saver';
import { CodeValueService } from './code-value.service';
import { PhxLocalizationService } from './phx-localization.service';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { IBusinessTaxEINNumberInUse, IIsInUse, OrganizationNumberValidationType } from '../../organization/models';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PhoenixCommonModuleResourceKeys } from '../PhoenixCommonModule.resource-keys';
import { HttpResponse } from '@angular/common/http';
import { TextMaskConfig } from 'angular2-text-mask/src/angular2TextMask';
import { AppStorageService } from './app-storage.service';
import { StorageService } from 'ngx-webstorage-service';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private isUploadingComplianceDocumentIdSubject: BehaviorSubject<number | null> = new BehaviorSubject(null);
  isUploadingComplianceDocumentId$: Observable<number | null> = this.isUploadingComplianceDocumentIdSubject.asObservable();

  constructor(private winRef: WindowRefService,
              private toastService: ToastService,
              private codeValueService: CodeValueService,
              private localizationService: PhxLocalizationService,
              private apiService: ApiService,
              private router: Router,
              @Inject(AppStorageService) private storageService: StorageService) {
  }

  get window(): any {
    return this.winRef.nativeWindow;
  }

  /** @deprecated Use direct constant import instead  */
  get ApplicationConstants(): typeof PhxConstants {
    return PhxConstants;
  }

  /** @deprecated Use direct constant import instead  */
  get CodeValueGroups(): typeof CodeValueGroups {
    return CodeValueGroups;
  }

  /** @deprecated Use direct constant import instead  */
  get api2Url(): any {
    return environment.apiUrl;
  }

  get browserInfo(): { name: string, version: string } {
    const ua = navigator.userAgent;
    let tem;
    let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE ', version: (tem[1] || '') };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR\/(\d+)/);
      if (tem !== null) {
        return { name: 'Opera', version: tem[1] };
      }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    tem = ua.match(/version\/(\d+)/i);
    if (tem) {
      M.splice(1, 1, tem[1]);
    }
    return {
      name: M[0],
      version: M[1]
    };
  }

  public bearerToken(): string {
    return this.storageService.get('BearerToken');
  }

  public logError(message: string, keepOpen: boolean = false): void {
    this.toastService.logError(message, keepOpen);
  }

  public logSuccess(message: string, keepOpen: boolean = false): void {
    this.toastService.logSuccess(message, keepOpen);
  }

  public logWarning(message: string, keepOpen: boolean = false): void {
    this.toastService.logWarning(message, keepOpen);
  }

  public logWarningNoIcon(message: string, keepOpen: boolean = false): void {
    this.toastService.showWarningToastNoIcon(message, keepOpen);
  }

  public logInfo(message: string, keepOpen: boolean = false): void {
    this.toastService.logInfo(message, keepOpen);
  }

  public parseResponseError(responseError: any, overloadPropertyName?: string): Array<{ PropertyName: string, Message: string }> {
    return this.responseErrorMessages(responseError, overloadPropertyName);
  }


  getObjectValues(obj) {
    if (obj && typeof obj === 'object') {
      return Object.keys(obj).map(e => obj[e]);
    } else {
      return [];
    }
  }

  public getObjectKeyByValue(collection, value) {
    const kArray = Object.keys(collection);                 // Creating array of keys
    const vArray = this.getObjectValues(collection);        // Creating array of values
    const vIndex = vArray.indexOf(value);                   // Finding value index

    return kArray[vIndex];                                  // Returning key by value index
  }

  public logValidationMessages(validationMessages: any) {
    const newLine = '\r\n';
    let logErrorMessage = '';
    if (!this.isEmptyObject(validationMessages)) {
      validationMessages.forEach(validationMessage => {
        logErrorMessage += newLine + validationMessage.PropertyName + ': ' + validationMessage.Message;
      });
    }
    if (logErrorMessage.length > 0) {
      // fix me
      // this.toastService.logError(logErrorMessage);
    }
  }

  // add:NH
  public saveStringAsFile(inputStr: string, fileContentType: string, fileCharset: string, fileName: string): void {
    saveAs(this.stringToBlob(inputStr, fileContentType, fileCharset), fileName);
  }

  public base64FileSaveAs(base64FileStreamOrString: string, fileContentType: string, fileCharset: string, fileName: string): void {
    const blob = this.base64ToBlob(base64FileStreamOrString, fileContentType, fileCharset);
    saveAs(blob, fileName);
  }

  public getUrlParams(prop: string) {
    prop = prop.toLowerCase();
    const params = {};
    const search = window.location.href.slice(window.location.href.indexOf('?') + 1);
    const definitions = search.split('&');

    definitions.forEach((val,) => {
      const parts = val.split('=', 2);
      params[parts[0].toLowerCase()] = decodeURIComponent(parts[1]);
    });

    return (prop && prop in params) ? params[prop] : params;
  }

  public compareFnToSortObjects(key, order = 'asc') {
    return (a, b) => {
      let comparison = 0;

      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return comparison;
      }

      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }

      return order === 'desc' ? comparison * -1 : comparison;
    };
  }

  public setMask(value: number | string): TextMaskConfig {
    let mask: TextMaskConfig;
    switch (+value) {
      case PhxConstants.Country.CA:
        mask = { mask: PhxConstants.Mask.CA };
        break;
      case PhxConstants.Country.US:
        mask = { mask: PhxConstants.Mask.US };
        break;
      case PhxConstants.Country.MX:
        mask = { mask: PhxConstants.Mask.MX };
        break;
      case PhxConstants.Country.DE:
        mask = { mask: PhxConstants.Mask.DE };
        break;
      case PhxConstants.OtherMasks.Phone:
        mask = { mask: PhxConstants.Mask.PHONE };
        break;
      case PhxConstants.OtherMasks.BankCode:
        mask = { mask: PhxConstants.Mask.BANKCODE, guide: false };
        break;
      case PhxConstants.OtherMasks.BranchCode:
        mask = { mask: PhxConstants.Mask.BRANCHCODE, guide: false };
        break;
      case PhxConstants.OtherMasks.BankAccountNumber:
        mask = { mask: PhxConstants.Mask.BANKACCOUNTNUMBER, guide: false };
        break;
      default:
        mask = { mask: false };
        break;
    }
    return mask;
  }

  public getUserProfileTypeSufix(profileType: number): string {
    switch (profileType) {
      case PhxConstants.UserProfileType.Organizational:
        return 'organizational';
      case PhxConstants.UserProfileType.Internal:
        return 'internal';
      case PhxConstants.UserProfileType.WorkerTemp:
        return 'workertemp';
      case PhxConstants.UserProfileType.WorkerCanadianSp:
        return 'workercanadiansp';
      case PhxConstants.UserProfileType.WorkerCanadianInc:
        return 'workercanadianinc';
      case PhxConstants.UserProfileType.WorkerSubVendor:
        return 'workersubvendor';
      case PhxConstants.UserProfileType.WorkerUnitedStatesW2:
        return 'workerunitedstatesw2';
      case PhxConstants.UserProfileType.WorkerUnitedStatesLLC:
        return 'workerunitedstatesllc';
    }
  }

  // temp work around untill we have profile type id from backend in search to use above switch case
  public getUserProfileTypeFromDescription(profileType: string): string {
    switch (profileType) {
      case 'Organizational':
        return 'organizational';
      case 'Internal':
        return 'internal';
      case 'Temp':
        return 'workertemp';
      case 'Canadian SP':
        return 'workercanadiansp';
      case 'Inc worker':
        return 'workercanadianinc';
      case 'Subvendor worker':
        return 'workersubvendor';
      case 'W2 Worker':
        return 'workerunitedstatesw2';
      case 'LLC Worker':
        return 'workerunitedstatesllc';
    }
  }

  // temp work around untill we have profile type id from backend in search to use above switch case
  public getUserProfileTypeFromProfileTypeForCardDisplay(profileType: string): string {
    switch (profileType) {
      case 'Organizational':
        return 'Organizational';
      case 'Internal':
        return 'Internal';
      case 'Temp':
        return 'Temp';
      case 'Canadian SP':
        return 'SP';
      case 'Inc worker':
        return 'CDN Inc';
      case 'Subvendor worker':
        return 'SV Worker';
      case 'W2 Worker':
        return 'W2';
      case 'US Inc Worker':
        return 'US Inc';
    }
  }

  /**
   * Translation for status based on entity
   * Expand this switch case to support more entities
   */
  public getStatusCodeValueGroups(entityTypeId: number): string {
    // Check if entity has a state machine, get the status codevalue group from the cache
    const statusTableNames: { EntityTypeId: number, StatusTableName: string }[] = (window as any).PhxStateEntityStatusTableNames;
    const match = statusTableNames.find(x => x.EntityTypeId === entityTypeId);

    if (match) {
      // Return state machine status table name
      return match.StatusTableName;
    } else {
      // Anything that doesn't have a state machine match can go here
      switch (entityTypeId) {
      }
    }
  }

  /**
   * Get the translated description based on action
   * Default resource is in workflow.stateHistory.{actionCode}
   *
   * If entity based custom description is desired, alternate resource is in workflow.{entityTypeCode}History.{actionCode}
   * @param debug Shows the state action code if no text is found
   */
  public getHistoryDescription(history: StateHistory, entityTypeId: number, debug?: boolean): string {
    if (history) {
      const stateActionCode = this.codeValueService.getCodeValueCode(history.StateActionId, CodeValueGroups.StateAction) || '';
      const entityTypeCode = this.codeValueService.getCodeValueCode(entityTypeId, CodeValueGroups.EntityType) || '';
      const transKeyCustom = `workflow.${entityTypeCode.charAt(0).toLowerCase() + entityTypeCode.slice(1)}History.${stateActionCode}`;
      const transKeyDefault = `workflow.stateHistory.${stateActionCode}`;
      const actionedBy = history.IsSystem
        ? this.localizationService.translate(PhoenixCommonModuleResourceKeys.generic.system).toLowerCase()
        : history.CreatedByFullName;
      const descCustom = this.localizationService.translate(transKeyCustom, actionedBy, history.UserComment || '', history.Note || '');
      const descDefault = this.localizationService.translate(transKeyDefault, actionedBy, history.UserComment || '', history.Note || '');
      if (transKeyCustom === descCustom) {
        // no custom translation
        if (transKeyDefault === descDefault) {
          // no default translation
          return debug ? `DEBUG - ${stateActionCode}` : null;
        } else {
          return descDefault;
        }
      } else {
        return descCustom;
      }
    }
  }

  isEmptyObject(obj) {
    for (const prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }
    return true;
  }

  responseErrorMessages(responseError, overloadPropertyName) {
    let validationMessages: Array<ValidationMessage> = [];
    const newLine = '\r\n';

    if (responseError && !this.isEmptyObject(responseError)) {
      let logErrorMessage = '';
      if (!this.isEmptyObject(responseError.ModelState)) {
        _.each(responseError.ModelState, (responseErrorValue, responseErrorKey) => {

          if (responseErrorKey.indexOf('Validation Summary') >= 0 ||
            responseErrorKey.indexOf('ValidationSummary') >= 0 ||
            responseErrorKey.indexOf('Hide Property Name') >= 0 ||
            responseErrorKey.indexOf('HidePropertyName') >= 0) {
            responseErrorKey = '';
          }

          if (typeof overloadPropertyName !== 'undefined' && overloadPropertyName !== null && overloadPropertyName.length > 0) {
            responseErrorKey = overloadPropertyName;
          }

          if (responseErrorValue.Errors) {
            _.each(responseErrorValue.Errors, (errorValue,) => {
              validationMessages.push({
                PropertyName: responseErrorKey, Message: errorValue.ErrorMessage
              });
            });
          } else if (Object.prototype.toString.call(responseErrorValue) === '[object Array]') {
            _.each(responseErrorValue, (errorValue,) => {
              validationMessages.push({
                PropertyName: responseErrorKey, Message: errorValue
              });
            });
          } else {
            validationMessages.push({
              PropertyName: responseErrorKey, Message: responseErrorValue
            });
          }
        }); // end of _.each

        if (validationMessages.length > 0) {
          let message;
          if (validationMessages.length === 1) {
            message = (window as any).PhxTranslations.common.generic.oneValidationErrorMessage;
          } else {
            message = (window as any).PhxTranslations.common.generic.multipleValidationErrorMessage;
          }
          logErrorMessage += message.replace(/\{0\}/g, validationMessages.length);
        }
      } else {
        let message;
        // if (!isEmptyObject(responseError) && responseError.CommandName) {
        //    logErrorMessage += responseError.CommandName ? newLine + 'CommandName: ' + responseError.CommandName : '';
        // }
        if (!this.isEmptyObject(responseError.ValidationMessages)) {
          validationMessages = responseError.ValidationMessages;
          if (validationMessages.length === 1) {
            message = (window as any).PhxTranslations.common.generic.oneValidationErrorMessage;
          } else {
            message = (window as any).PhxTranslations.common.generic.multipleValidationErrorMessage;
          }

          logErrorMessage += message.replace(/\{0\}/g, validationMessages.length);
        }

        // Don't show status: 500 toast error for Concurrency exception, its handled by phoenixapi-service.js
        logErrorMessage += responseError.status && responseError.status !== 400 && !responseError.isConcurrencyException ? newLine + 'status: ' + responseError.status : '';

        if (!this.isEmptyObject(responseError.InnerException) && !this.isEmptyObject(responseError.InnerException.InnerException)) {
          logErrorMessage += responseError.InnerException.InnerException.Message ? newLine + responseError.InnerException.InnerException.Message + newLine : '';
          logErrorMessage += responseError.InnerException.InnerException.ExceptionType ? newLine + 'ExceptionType: ' + responseError.InnerException.InnerException.ExceptionType : '';
          logErrorMessage += responseError.InnerException.InnerException.ExceptionMessage ? newLine + 'ExceptionMessage: ' + responseError.InnerException.InnerException.ExceptionMessage : '';
          // logErrorMessage += responseError.InnerException.InnerException.StackTrace ? newLine + 'StackTrace: ' + responseError.InnerException.InnerException.StackTrace : '';
        }
        if (!this.isEmptyObject(responseError.InnerException)) {
          logErrorMessage += responseError.InnerException.Message ? newLine + responseError.InnerException.Message + newLine : '';
          logErrorMessage += responseError.InnerException.ExceptionType ? newLine + 'ExceptionType: ' + responseError.InnerException.ExceptionType : '';
          logErrorMessage += responseError.InnerException.ExceptionMessage ? newLine + 'ExceptionMessage: ' + responseError.InnerException.ExceptionMessage : '';
          // logErrorMessage += responseError.InnerException.StackTrace ? newLine + 'StackTrace:' + responseError.InnerException.StackTrace : '';
        }
        if (!this.isEmptyObject(responseError) && responseError.Message) {
          logErrorMessage += responseError.Message ? newLine + responseError.Message + newLine : '';
        }
        if (!this.isEmptyObject(responseError) && responseError.ExceptionType) {
          logErrorMessage += responseError.ExceptionType ? newLine + 'ExceptionType: ' + responseError.ExceptionType : '';
        }
        if (!this.isEmptyObject(responseError) && responseError.ExceptionMessage) {
          logErrorMessage += responseError.ExceptionMessage ? newLine + 'ExceptionMessage: ' + responseError.ExceptionMessage : '';
        }
      }

      if (logErrorMessage.length > 0) {
        // fix me
        // this.toastService.logError(logErrorMessage);
      }
    }
    return validationMessages;
  }

  isBusinessTaxEINNumberInUse(
    num: string, type: OrganizationNumberValidationType,
    countryId: number = PhxConstants.CountryCanada,
    organizationId?: number,
    profileId?: number,
    taxType?: string
  ): Observable<IIsInUse> {
    num = num !== null ? num.trim() : '';
    if (num.length > 0) {
      switch (type) {
        case OrganizationNumberValidationType.BusinessNumber:
          return this.isBusinessNumberInUse(num, countryId, organizationId, profileId).pipe(this.mapIIsInUse());
        case OrganizationNumberValidationType.EmployerIdentificationNumber:
          return this.isEmployerIdentificationNumberInUse(num, organizationId).pipe(this.mapIIsInUse());
        case OrganizationNumberValidationType.TaxNumber:
          return this.isTaxNumberInUse(num, taxType, organizationId, profileId).pipe(this.mapIIsInUse());
      }
    } else {
      return new Observable(observer => {
        observer.next({
          IsInUse: false,
          IsInUseName: '',
          IsInUseLink: ''
        } as IIsInUse);
        observer.complete();
      });
    }
  }

  convertUTCToLocal(dateTime: string | Date | Moment) {
    if (!dateTime) {
      return null;
    }
    
    if (dateTime instanceof Date) {
      dateTime = formatDate(formatDate(dateTime, 'yyyy-MM-ddTHH:mm:ss', PhxLocalizationService.locale) + 'Z', 'yyyy-MM-ddTHH:mm:ssZZZZZ', PhxLocalizationService.locale);     
    }

    const date = moment.utc(dateTime);
    return date.local().format();
  }

  convertLocalToUTC(dateTime: Date | Moment | string) {
    if (!dateTime) {
      return null;
    }

    const date = moment(dateTime);
    return date.utc();
  }

  saveReportExportFileFromHttpResponse(res: HttpResponse<Blob>) {
    const fileData = res.body;
    let fileName = 'Report-Export.csv';
    const contentDisposition = res.headers.get('Content-Disposition');
    if (contentDisposition) {
      const fileNameHeader = contentDisposition.split(';')[1];
      if (fileNameHeader) {
        fileName = fileNameHeader.split('=')[1] || fileName;
        fileName = fileName.replace(/"/g, '');
      }
    }
    
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result as string;
      const bom = '\uFEFF';
      const utf8Blob = new Blob([bom + text], { type: 'text/csv;charset=utf-8;' });
      saveAs(utf8Blob, fileName);
    };
    reader.onerror = (error) => {
      console.error('Error reading file:', error);
    };

    reader.readAsText(fileData, 'utf-8');
  }
  

  generateRequestObject(tableState) {
    const searchObj = tableState && tableState.search && tableState.search.predicateObject ? tableState.search.predicateObject : null;
    const sortObj = tableState && tableState.sort && tableState.sort.predicate ? tableState.sort.predicate + (tableState.sort.reverse ? ' desc ' : '') : null;
    let currentPage = tableState && tableState.pagination && tableState.pagination.currentPage ? tableState.pagination.currentPage : 1;
    const pageSize = tableState && tableState.pagination && tableState.pagination.pageSize ? tableState.pagination.pageSize : 30;
    currentPage--;
    let oDataParams = oreq.request();
    if (Object.keys(searchObj).length > 0) {
      oDataParams = oDataParams.withFilter(oreq.filter().smartTableObjectConverter(searchObj));
    }
    if (sortObj) {
      oDataParams = oDataParams.withOrderby(sortObj);
    }
    if (!(tableState && tableState.pagination && tableState.pagination.isDisabled === true)) {
      oDataParams = oDataParams
        .withTop(pageSize)
        .withSkip(currentPage * pageSize)
        .withInlineCount();
    } else {
      oDataParams = oDataParams.withInlineCount();
    }
    return oDataParams;
  }

  private base64ToBlob(base64FileStreamOrString: string, fileContentType: string, fileCharset?: string) {
    fileContentType = fileCharset ? fileContentType + ';charset=' + fileCharset + ';' : fileContentType;
    const binary = atob(base64FileStreamOrString);
    const buf = new ArrayBuffer(binary.length);
    const arr = new Uint8Array(buf);
    for (let i = 0; i < binary.length; i++) {
      arr[i] = binary.charCodeAt(i);
    }
    return new Blob([buf], { type: fileContentType });
  }

  // add:NH
  private stringToBlob(inputStr: string, fileContentType: string, fileCharset?: string) {
    return new Blob([inputStr], { type: fileCharset ? fileContentType + ';charset=' + fileCharset + ';' : fileContentType });
  }

  private isBusinessNumberInUse(businessNumber: string, countryId: number, organizationId: number, profileId: number): Observable<IBusinessTaxEINNumberInUse> {
    return this.apiService.query<IBusinessTaxEINNumberInUse>(
      `org/isBusinessNumberInUse?businessNumber=${businessNumber}&countryId=${countryId}&organizationId=${organizationId}&profileId=${profileId}`, false);
  }

  private isTaxNumberInUse(taxNumber: string, taxType: string, organizationId: number, profileId: number): Observable<IBusinessTaxEINNumberInUse> {
    return this.apiService.query<IBusinessTaxEINNumberInUse>(
      `org/isTaxNumberInUse?taxNumber=${taxNumber}&taxType=${taxType}&organizationId=${organizationId}&profileId=${profileId}`, false);
  }

  private isEmployerIdentificationNumberInUse(employerIdentificationNumber: string, organizationId: number): Observable<IBusinessTaxEINNumberInUse> {
    return this.apiService.query<IBusinessTaxEINNumberInUse>(
      `org/isEmployerIdentificationNumberInUse?employerIdentificationNumber=${employerIdentificationNumber}&organizationId=${organizationId}`, false);
  }

  private mapIIsInUse() {
    return map((response: IBusinessTaxEINNumberInUse) => {
      const rep: IIsInUse = {
        IsInUse: false,
        IsInUseName: '',
        IsInUseLink: ''
      };
      if (response && response.IsInUse) {
        rep.IsInUse = true;
        rep.IsInUseName = response.InUseName;
        rep.IsInUseLink = this.createBusinessTaxEINNumberInUseLink(response);
      }
      return rep;
    });
  }

  private createBusinessTaxEINNumberInUseLink(response: IBusinessTaxEINNumberInUse): string {
    if (response.InUseOrgId) {
      return this.router.createUrlTree([`/next/organization/${response.InUseOrgId}`]).toString();
    } else if (response.InUseUserProfileId) {
      return this.router.createUrlTree([`/next/contact/userprofile/${response.InUseUserProfileId}`]).toString();
    }
  }
  
  initCountryCodes(): { code: string }[] {
    const phoneNumberUtil = PhoneNumberUtil.getInstance();
    const countries: string[] = phoneNumberUtil.getSupportedRegions();
    const countryCodes: number[] = [90];
    
    countries.forEach((country) => {
      const countryCode = phoneNumberUtil.getCountryCodeForRegion(country);
      if (countryCodes.indexOf(countryCode) === -1) {
        countryCodes.push(countryCode);
      }
    });
  
    countryCodes.sort((a, b) => (a > b ? 1 : -1));
    
    const newCountryCodes: { code: string }[] = countryCodes.map((value: any) => ({ code: '+' + value }));
    
    return newCountryCodes;

  }

  /** NOTE: we want to track when a file is uploading */
  setAsIsUploading(documentComplianceId: number | null) {
    this.isUploadingComplianceDocumentIdSubject.next(documentComplianceId);
  }
}

