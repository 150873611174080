import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import DevExpress from 'devextreme';

import { GridColumnModel } from '../../../common/model/grid-column.model';
import { MAIL_CAMPAIGN_PATHS } from '../../mass-mailer-routing/mail-campaign-paths.const';
import { MailCampaignHistoryItem } from './models/mail-campaign-history-item.model';
import { ApiService, CodeValueGroups, CodeValueService, CommonService, NavigationService, PhxConstants, PhxLocalizationService, WindowRefService } from '../../../common';
import { PhxDataTableConfiguration, PhxDataTableSelectionMode, PhxDataTableStateSavingMode } from '../../../common/model';
import { FboMonolithMailCampaignService } from '../../../common/data-services/fbo-monolith-mail-campaign/fbo-monolith-mail-campaign.service';
import { MailCampaignDto } from '../../../common/data-services/fbo-monolith-mail-campaign/models/mail-campaign.dto';
import { CommonListsObservableService } from '../../../common/lists/lists.observable.service';
import { ICommonListsItem } from '../../../common/lists';
import { MailCampaignPayload } from '../mail-stepper/models/mail-campaign-payload.model';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.less']
})
export class HistoryComponent implements OnInit {
  public readonly campaigns$: Observable<MailCampaignHistoryItem[]> = forkJoin([
    this.fboMonolithMailCampaignService.getMailCampaignsList(),
    this.commonListsObservableService.listUserProfileInternal$().pipe(filter(Boolean), first())
  ]).pipe(map(([{ Items }, profilesInternal]) => this.convertMailCampaignsToMailCampaignHistoryItems(Items, profilesInternal)));

  public readonly dataTableConfiguration = new PhxDataTableConfiguration({
    hidePager: false,
    pageSize: 25,
    saveUserFilters: false,
    enableExport: false,
    showFilter: true,
    stateSavingMode: PhxDataTableStateSavingMode.None,
    showSearch: false,
    showGrouping: false,
    selectionMode: PhxDataTableSelectionMode.None,
    showOpenInNewTab: true
  });

  public readonly columns: Array<GridColumnModel<MailCampaignHistoryItem> & DevExpress.ui.dxDataGrid.Column> = [
    {
      caption: $localize`:@@mailCampaign.mailCampaignTime:Mail Campaign Time`,
      dataField: 'dateTime',
      allowSearch: true
    },
    {
      caption: $localize`:@@mailCampaign.requestedBy:Requested By`,
      dataField: 'requestedBy',
      allowSearch: true
    },
    {
      caption: $localize`:@@mailCampaign.mailSubject:Mail Subject`,
      dataField: 'mailSubject',
      allowSearch: true
    },
    {
      caption: $localize`:@@mailCampaign.numberOfRecipients:No. of Recipients`,
      dataField: 'recipientsCount',
      allowSearch: true
    },
    {
      caption: $localize`:@@common.status:Status`,
      dataField: 'status',
      allowSearch: true
    }
  ];

  public readonly mailCampaignPaths = MAIL_CAMPAIGN_PATHS;
  public isCreatingMailCampaign = false;

  constructor(
    private navigationService: NavigationService,
    private router: Router,
    private winRef: WindowRefService,
    private fboMonolithMailCampaignService: FboMonolithMailCampaignService,
    private commonListsObservableService: CommonListsObservableService,
    private apiService: ApiService,
    private commonService: CommonService,
    private codeValueService: CodeValueService
  ) {}

  ngOnInit(): void {
    this.navigationService.disableBreadcrumbs();
  }

  public contextMenuOpenTab(data: MailCampaignHistoryItem): void {
    this.winRef.openUrl(`#${MAIL_CAMPAIGN_PATHS.viewCampaign.getRouterLink(data.id)}`, '_blank');
  }

  public rowClick({ data }: { data: MailCampaignHistoryItem }): void {
    if (data.statusId === PhxConstants.MailCampaignStatus.Sent) {
      void this.router.navigate([MAIL_CAMPAIGN_PATHS.viewCampaign.getRouterLink(data.id)]);
    } else {
      void this.router.navigate([MAIL_CAMPAIGN_PATHS.newMailCampaign.getRouterLink(data.id)]);
    }
  }

  public async createNewMailCampaign(): Promise<void> {
    this.isCreatingMailCampaign = true;
    try {
      const newMailCampaign: MailCampaignPayload = {
        ToUserProfileIds: '',
        SubjectLine: '',
        BodyContent: '',
        AssignmentIds: ''
      };

      const { EntityId } = await this.apiService.command('MailCampaignSave', newMailCampaign);
      void this.router.navigate([this.mailCampaignPaths.newMailCampaign.getRouterLink(EntityId)]);
    } catch (e) {
      this.commonService.logError($localize`:@@mailCampaign.errorCreatingNewMailCampaign:Error creating new mail campaign!`);
    } finally {
      this.isCreatingMailCampaign = false;
    }
  }

  private convertMailCampaignsToMailCampaignHistoryItems(mailCampaignsList: MailCampaignDto[], profilesInternal: ICommonListsItem[]): MailCampaignHistoryItem[] {
    return mailCampaignsList.map(mailCampaign => ({
      id: mailCampaign.Id,
      dateTime: mailCampaign.SendDate ? formatDate(mailCampaign.SendDate, PhxConstants.DateFormat.MMM_ddComma_yyyy_HH_mm_ss, PhxLocalizationService.locale) : '',
      status: this.codeValueService.getCodeValueText(mailCampaign.MailCampaignStatusId, CodeValueGroups.MailCampaignStatus),
      recipientsCount: mailCampaign.ToUserProfileIds?.split(',').filter(Boolean).length,
      mailSubject: mailCampaign.SubjectLine,
      requestedBy: profilesInternal.find(u => u.Id === mailCampaign.FromUserId)?.DisplayText ?? mailCampaign.FromUserId.toString(),
      statusId: mailCampaign.MailCampaignStatusId
    }));
  }
}
