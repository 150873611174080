import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { forkJoin, Observable, of } from 'rxjs';
import { formatDate } from '@angular/common';
import { filter, first, switchMap, tap } from 'rxjs/operators';

import { BaseComponentOnDestroy } from '../../../common/epics/base-component-on-destroy';
import { EmailTemplateFormValues } from '../../models/email-template-form.model';
import { MAIL_CAMPAIGN_PATHS } from '../../mass-mailer-routing/mail-campaign-paths.const';
import { NavigationService, PhxConstants, PhxLocalizationService } from '../../../common';
import { PhxFormControlLayoutType } from '../../../common/model';
import { FboMonolithMailCampaignService } from '../../../common/data-services/fbo-monolith-mail-campaign/fbo-monolith-mail-campaign.service';
import { CommonListsObservableService } from '../../../common/lists/lists.observable.service';
import { PreviewRecipient } from '../../models/preview-recipient.model';
import { RecipientsDetailsLoaderService } from '../../services/recipients-details-loader/recipients-details-loader.service';
import { MailCampaignDto } from '../../../common/data-services/fbo-monolith-mail-campaign/models/mail-campaign.dto';

@Component({
  selector: 'app-campaign-view',
  templateUrl: './campaign-view.component.html',
  styleUrls: ['./campaign-view.component.less']
})
export class CampaignViewComponent extends BaseComponentOnDestroy implements OnInit {
  public message$: Observable<EmailTemplateFormValues>;
  public recipients$: Observable<PreviewRecipient[]> = of([]);

  public readonly mailCampaignPaths = MAIL_CAMPAIGN_PATHS;
  public readonly campaignDataForm = new FormGroup({
    dateTime: new FormControl(''),
    requestedBy: new FormControl('')
  });
  public readonly phxFormControlLayoutType = PhxFormControlLayoutType;
  public mailCampaignId: number;

  constructor(
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private fboMonolithMailCampaignService: FboMonolithMailCampaignService,
    private commonListsObservableService: CommonListsObservableService,
    private recipientsDetailsLoaderService: RecipientsDetailsLoaderService
  ) {
    super();
  }

  ngOnInit(): void {
    this.navigationService.disableBreadcrumbs();
    this.getCampaignById();
  }

  private getCampaignById(): void {
    this.activatedRoute.paramMap
      .pipe(
        this.takeUntilDestroyed,
        tap(parametersMap => {
          this.mailCampaignId = +parametersMap.get(MAIL_CAMPAIGN_PATHS.viewCampaign.campaignParamName);
        }),
        switchMap(() =>
          forkJoin([this.fboMonolithMailCampaignService.getCampaignById(this.mailCampaignId), this.commonListsObservableService.listUserProfileInternalAll$().pipe(filter(Boolean), first())])
        )
      )
      .subscribe(([mailCampaign, profilesInternal]) => {
        void this.setRecipients(mailCampaign);
        this.campaignDataForm.patchValue({
          dateTime: mailCampaign.SendDate ? formatDate(mailCampaign.SendDate, PhxConstants.DateFormat.MMM_dd_yyyy_HH_mm, PhxLocalizationService.locale) : undefined,
          requestedBy: profilesInternal.find(u => u.Id === mailCampaign.FromUserId)?.DisplayText ?? mailCampaign.FromUserId.toString()
        });
        this.message$ = of({
          subject: mailCampaign.SubjectLine,
          attachments: [],
          message: mailCampaign.BodyContent
        });
      });
  }

  private async setRecipients(mailCampaign: MailCampaignDto): Promise<void> {
    const recipients = await this.recipientsDetailsLoaderService.loadSavedRecipients(mailCampaign);
    const profilesLookup = new Map(recipients.map(profile => [profile.Associations.WorkerProfileId, profile]));
    const ids = mailCampaign.ToUserProfileIds.split(',').map(i => +i);
    this.recipients$ = of(
      ids.map(id => ({
        WorkerEmail: profilesLookup.get(id)?.WorkerEmail,
        WorkerDisplayName: profilesLookup.get(id)?.WorkerDisplayName ?? id.toString()
      }))
    );
  }
}
