import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormControlStatus } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';

import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

import { EmailComponent } from '../email/email.component';
import { MAIL_CAMPAIGN_PATHS } from '../../mass-mailer-routing/mail-campaign-paths.const';
import { ApiService, CommonService, NavigationService, PhxConstants } from '../../../common';
import { PhxFormControlLayoutType } from '../../../common/model';
import { RecipientSearchResult } from '../../models/recipient-search-result.model';
import { WizardSteps } from './models/wizard-steps.model';
import { FilterTemplateSaveService } from '../../services/filter-template-save/filter-template-save.service';
import { BaseComponentOnDestroy } from '../../../common/epics/base-component-on-destroy';
import { MailCampaignSelectionService } from '../../services/mail-campaign-utility/mail-campaign-selection.service';
import { FILTER_TEMPLATE_SAVE_OPTIONS } from '../../models/filter-template-save-options.model';
import { FilterTemplateSaveForm } from '../../models/filter-template-save-form.model';
import { MAIL_CAMPAIGN_SELECTION_TYPE, MailCampaignSelectionType } from '../../../common/data-services/fbo-monolith-mail-campaign-utility/models/mail-campaign-selection-type.model';
import { GlobalSearchFacetsStateService } from '../../../common/services/global-search-facets-state/global-search-facets-state.service';
import { SavedTemplate } from '../../models/saved-template.model';
import { convertFacetsConfigToFacetsStringPayload } from './utils/convert-facets-config-to-facets-string-payload.util';
import { MailCampaignPayload } from './models/mail-campaign-payload.model';
import { FboMonolithMailCampaignService } from '../../../common/data-services/fbo-monolith-mail-campaign/fbo-monolith-mail-campaign.service';
import { EmailTemplateFormValues } from '../../models/email-template-form.model';
import { RecipientsDetailsLoaderService } from '../../services/recipients-details-loader/recipients-details-loader.service';

@Component({
  selector: 'app-mail-stepper',
  templateUrl: './mail-stepper.component.html',
  styleUrls: ['./mail-stepper.component.less'],
  providers: [FilterTemplateSaveService]
})
export class MailStepperComponent extends BaseComponentOnDestroy implements OnInit {
  @ViewChild('emailStep') emailStep: EmailComponent;
  @ViewChild('stepper') private stepper: MatStepper;

  public recipients: RecipientSearchResult[] = [];

  public readonly steps: WizardSteps = {
    one: {
      isComplete: false,
      index: 0,
      label: $localize`:@@mailCampaign.recipients:Recipients`
    },
    two: {
      isComplete: false,
      index: 1,
      label: $localize`:@@mailCampaign.template:Template`
    },
    three: {
      isComplete: false,
      index: 2,
      label: $localize`:@@mailCampaign.send:Send`
    }
  };

  public selectedIndex = 0;
  public readonly dateFormat = PhxConstants.DateFormat;

  public readonly scheduleTimeControl = new FormControl<Date>(null);
  public readonly minDate = new Date();
  public readonly phxFormControlLayoutType = PhxFormControlLayoutType;
  public readonly mailCampaignPaths = MAIL_CAMPAIGN_PATHS;
  public initialEmailFormValues: EmailTemplateFormValues = {
    message: '',
    attachments: [],
    subject: ''
  };
  public initialRecipients: RecipientSearchResult[] = [];
  public mailCampaignId?: number;

  constructor(
    private navigationService: NavigationService,
    private apiService: ApiService,
    private commonService: CommonService,
    private filterTemplateSaveService: FilterTemplateSaveService,
    private mailCampaignSelectionService: MailCampaignSelectionService,
    private globalSearchFacetsStateService: GlobalSearchFacetsStateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fboMonolithMailCampaignService: FboMonolithMailCampaignService,
    private recipientsDetailsLoaderService: RecipientsDetailsLoaderService
  ) {
    super();
  }

  ngOnInit(): void {
    this.navigationService.disableBreadcrumbs();
    this.subscribeToSaveFilterTemplate();
    this.mailCampaignSelectionService.loadSelections();
    this.loadSavedCampaignData();
  }

  public onEmailChange(status: FormControlStatus): void {
    this.steps.two.isComplete = status === 'VALID';
  }

  public selectRecipients(recipients: RecipientSearchResult[]): void {
    this.recipients = recipients;
    this.steps.one.isComplete = Boolean(recipients.length);
  }

  public async sendEmail(): Promise<void> {
    this.steps.three.isComplete = true;
    try {
      await this.submitMailCampaign();
      this.stepper.next();
    } catch (e) {
      this.commonService.logError($localize`:@@mailCampaign.errorCreatingNewMailCampaign:Error creating new mail campaign`);
      this.stepper.next();
    }
  }

  public stepChange(stepperChange: StepperSelectionEvent): void {
    this.selectedIndex = stepperChange.selectedIndex;
    void this.saveMailCampaign();
  }

  private subscribeToSaveFilterTemplate(): void {
    this.filterTemplateSaveService.filterTemplateSaveSettings$.pipe(this.takeUntilDestroyed).subscribe(async saveSettings => {
      const settingKeyToUtilityMap: Record<keyof FilterTemplateSaveForm, MailCampaignSelectionType> = {
        filter: MAIL_CAMPAIGN_SELECTION_TYPE.Filter,
        template: MAIL_CAMPAIGN_SELECTION_TYPE.Template
      };
      try {
        await Promise.all(
          Object.entries(saveSettings)
            .filter(([, { saveOption }]) => saveOption !== FILTER_TEMPLATE_SAVE_OPTIONS.dontSave)
            .map(([type, setting]) => {
              return this.mailCampaignSelectionService.saveSelection({
                Id: setting.saveOption === FILTER_TEMPLATE_SAVE_OPTIONS.saveAsNew ? null : setting.idToOverride,
                Name: setting.name,
                MailCampaignSelectionTypeId: settingKeyToUtilityMap[type as keyof FilterTemplateSaveForm],
                Content:
                  settingKeyToUtilityMap[type as keyof FilterTemplateSaveForm] === MAIL_CAMPAIGN_SELECTION_TYPE.Filter
                    ? convertFacetsConfigToFacetsStringPayload(this.globalSearchFacetsStateService.currentGlobalFacetsConfig)
                    : this.getSavedTemplatePayload()
              });
            })
        );
        this.mailCampaignSelectionService.loadSelections();
        this.filterTemplateSaveService.notifyHasFinishedSaving();
        if (this.steps.three.isComplete) {
          void this.router.navigate([MAIL_CAMPAIGN_PATHS.history.getRouterLink()]);
        }
      } catch (e) {
        console.error(e);
      }
    });
  }

  private getSavedTemplatePayload(): string {
    const payload: SavedTemplate = this.emailStep.emailTemplateForm.getRawValue();
    return JSON.stringify(payload);
  }

  private async saveMailCampaign(): Promise<void> {
    try {
      await this.apiService.command('MailCampaignSave', this.getMailCampaignPayload());
    } catch (e) {
      this.commonService.logError($localize`:@@mailCampaign.errorSavingMailCampaign:Error saving the mail campaign`);
    }
  }

  private async submitMailCampaign(): Promise<void> {
    try {
      await this.apiService.command('MailCampaignSubmit', { Id: this.mailCampaignId });
    } catch (e) {
      this.commonService.logError($localize`:@@mailCampaign.errorSubmittingMailCampaign:Error submitting the mail campaign with id ${this.mailCampaignId}`);
    }
  }

  private getMailCampaignPayload(): MailCampaignPayload {
    const { subject, message } = this.emailStep.emailTemplateForm.getRawValue();
    const { profileIds, assignmentIds } = this.extractAssignmentIdsAndProfileIds(this.recipients);
    return {
      Id: this.mailCampaignId,
      ToUserProfileIds: profileIds,
      AssignmentIds: assignmentIds,
      SubjectLine: subject,
      BodyContent: message,
      SendDate: this.scheduleTimeControl.value?.toISOString()
    };
  }

  private loadSavedCampaignData(): void {
    this.activatedRoute.paramMap
      .pipe(
        this.takeUntilDestroyed,
        tap(parametersMap => {
          this.mailCampaignId = +parametersMap.get(MAIL_CAMPAIGN_PATHS.newMailCampaign.campaignParamName);
        }),
        switchMap(() => this.fboMonolithMailCampaignService.getCampaignById(this.mailCampaignId))
      )
      .subscribe(async campaign => {
        this.initialEmailFormValues = {
          message: campaign.BodyContent,
          subject: campaign.SubjectLine,
          attachments: []
        };
        this.initialRecipients = await this.recipientsDetailsLoaderService.loadSavedRecipients(campaign);
      });
  }

  private extractAssignmentIdsAndProfileIds(recipients: RecipientSearchResult[]): { assignmentIds: string; profileIds: string } {
    const assignmentIds = new Set<number>();
    const profileIds = new Set<number>();

    recipients.forEach(recipient => {
      assignmentIds.add(recipient.AssignmentId);
      profileIds.add(recipient.Associations.WorkerProfileId);
    });

    return {
      assignmentIds: Array.from(assignmentIds.values()).join(','),
      profileIds: Array.from(profileIds.values()).join(',')
    };
  }
}
