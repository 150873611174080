import { environment } from '../../../../environments/environment';
import { ApiService } from '../../services/api.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { IRuleResult, RuleStrictness, ComplianceStatus, IRuleCategory, IRuleGroup } from '../../model/rule-engine';
import { BaseComponentOnDestroy } from '../../epics/base-component-on-destroy';
import { catchError, debounceTime, filter, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { PhxSideBarService } from '../../services/phx-side-bar.service';
import { combineLatest, Observable, of } from 'rxjs';
import { UntypedFormBuilder } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { PhxConstants, PhxFormControlLayoutType } from '../../model';
import { GoogleAnalyticsService } from '../../services/google-analytics/google-analytics.service';
import { WindowRefService } from '../../services/WindowRef.service';
import { PhxLocalizationService } from '../../services/phx-localization.service';

type RuleResultWithGroups = IRuleResult & IRuleGroup;
@Component({
  selector: 'app-phx-panel-checklist',
  templateUrl: './phx-panel-checklist.component.html',
  styleUrls: ['./phx-panel-checklist.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhxPanelChecklistComponent extends BaseComponentOnDestroy implements OnInit, OnDestroy {

  /** NOTE: used for 'refresh' button spinner visual cue */
  loadingList = true;
  loadingColor = '#333';
  errorLoadingRules = false;

  allowUnfinishedFeatures: boolean;

  /** NOTE: entity type being checked - WO, org, onboarding, etc */
  currentEntityValue: any = null;
  entityType: string;

  /** NOTE: list of rule groups */
  ruleGroups: Array<IRuleGroup>;
  /** NOTE: list of rule groups when the user is using keyword filter */
  filteredRuleGroups: Array<IRuleGroup>;

  RuleStrictness = RuleStrictness;
  ComplianceStatus = ComplianceStatus;

  currentComplianceStatus: ComplianceStatus;

  filterStatus: boolean[] = [true, true, true];

  warningTotal: number;
  compliantTotal: number;
  nonCompliantTotal: number;
  ruleCategories: IRuleCategory[] = [];

  hidingCompliantRules = false;
  hidingWarningRules = false;
  hidingNoncompliantRules = false;
  hidingEverything = false;

  /** NOTE: keyword search form */
  form = this.formBuilder.group({
    keyword: null
  });
  inputOnlyLayoutType: PhxFormControlLayoutType = PhxFormControlLayoutType.InputOnly;

  constructor(
    private apiService: ApiService,
    private cdr: ChangeDetectorRef,
    private phxSidebarService: PhxSideBarService,
    private formBuilder: UntypedFormBuilder,
    private googleAnalyticsService: GoogleAnalyticsService,
    private winRef: WindowRefService,
    private phxLocalizationService: PhxLocalizationService
  ) {
    super();
    this.allowUnfinishedFeatures = environment.allowUnfinishedFeatures === 'true';
  }

  ngOnInit(): void {
    this.initEntityTypeChange();
    this.initEntityChange();
    this.initRefreshChecklistEmit();
    this.initKeyWordChange();
    this.initRefreshComplianceDocumentRulesEmit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  trackByFn(index: number, item: IRuleGroup) {
    return item.category;
  }

  resetFilters() {
    this.filterStatus = [true, true, true];
    this.applyFiltersAndSearch();
  }

  applyFiltersAndSearch() {
    let temporaryFilter: Array<IRuleResult> = [];
    this.filteredRuleGroups = cloneDeep(this.ruleGroups);

    this.hidingEverything = false;

    this.hidingCompliantRules = !this.filterStatus[0];
    this.hidingWarningRules = !this.filterStatus[2];
    this.hidingNoncompliantRules = !this.filterStatus[1];


    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < this.filteredRuleGroups.length; i++) {
      temporaryFilter = [];
      for (let j = 0; j < this.filterStatus.length; j++) {
        if (j === 0 && this.filterStatus[j] === true) {
          temporaryFilter.push(...this.getCompliantRules(this.filteredRuleGroups[i].rules));
        }
        else if (j === 1 && this.filterStatus[j] === true) {
          temporaryFilter.push(...this.getNonCompliantRules(this.filteredRuleGroups[i].rules));
        }
        else if (j === 2 && this.filterStatus[j] === true) {
          temporaryFilter.push(...this.getWarningRules(this.filteredRuleGroups[i].rules));
        }
      }

      if (this.form.controls.keyword.value) {
        this.filteredRuleGroups[i].rules = temporaryFilter
          .filter(x => x.ruleText.toLowerCase().includes(this.form.controls.keyword.value?.toLowerCase()))
          .sort((r1, r2) => r1.sortOrder - r2.sortOrder);

      } else {
        this.filteredRuleGroups[i].rules = temporaryFilter.sort((r1, r2) => r1.sortOrder - r2.sortOrder);
      }
    }

    if (!this.filteredRuleGroups.some(m => m.rules?.length > 0)) {
      this.hidingEverything = true;
    }
  }

  emitDocumentComplianceDataRefresh(rule: RuleResultWithGroups) {
    /** NOTE: we want the name of the document in the toast - in the rule that
     * name could include a list index - we want to remove it
     */
    let docName = '';
    const tmpRule = rule?.rules?.[0] ?? null;
    if (tmpRule) {
      const subcategory = tmpRule.ruleSubCategory ?? '';
      docName = subcategory.replace(` ${tmpRule.ruleSubCategoryId}`, '');
    }

    this.phxSidebarService.onRefreshDocumentComplianceRules.emit({
      complianceDocumentId: +rule.additionalInformation.ComplianceDocumentId,
      documentName: docName
    });
  }

  /** NOTE: the use of this is temporary to assess the usage of the compliancy 
   * checklist action items - mar 4/24 -  will eventually be removed - story #43109 */
  sendGoogleClickData(clickAction: string) {
    this.googleAnalyticsService.sendClickData({
      feature: 'Compliancy checklist',
      type: this.entityType,
      action: clickAction,
    });
  }

  viewDocument(rule: RuleResultWithGroups, index: number) {
    this.phxSidebarService.entityIdChange$().pipe(
      take(1)
    ).subscribe(entityId => {
      let entityTypeId = PhxConstants.SideBarEntityTypeId[this.entityType];
      if (this.entityType === PhxConstants.SideBarEntityType.Organization) {
        entityTypeId = rule.additionalInformation.EntityEntityTypeId;
      }

      this.winRef.nativeWindow.open(
        `#/next/compliance/document-view?id=${+rule.additionalInformation.ComplianceDocumentId}&entitytypeid=${entityTypeId}&entityid=${entityId}&index=${index}`,
        '_blank'
      );
    });
  }

  getApiUrl(entityType: string, metaData: any): string {
    /** NOTE: once rule engine is replaced with compliance service - this can go away */
    let endpointToReturn = environment.ruleEngineApiEndpoint;

    const documentComplianceIsActive = metaData?.documentComplianceDataFeatureIsActive ?? false;
    const isDocumentComplianceEntity = entityType === PhxConstants.RuleEngineEntityType.UserProfile || (
      (entityType === PhxConstants.RuleEngineEntityType.WorkOrderLegacy
        || entityType === PhxConstants.RuleEngineEntityType.Organization
      ) && !metaData?.documentComplianceDataExclusionList?.includes(metaData?.organizationClientId)
    );

    if (documentComplianceIsActive && isDocumentComplianceEntity) {
      endpointToReturn = environment.complianceServiceApiEndpoint;
    }

    return endpointToReturn;
  }

  /** NOTE: each entity type has it's own rule categories - on change get those categories */
  private initEntityTypeChange() {
    combineLatest([
      this.phxSidebarService.entityTypeChange$(),
      this.phxSidebarService.entityMetaDataChange$()
    ]).pipe(
      filter(([entityType]) => !!entityType),
      switchMap(([entityType, metaData]) => {
        this.entityType = entityType;
        const apiUrl = this.getApiUrl(this.entityType, metaData);
        return this.apiService.httpGetRequest<IRuleCategory[]>(`RuleEngine/GetRuleCategories/${entityType}`, apiUrl);
      }),
      takeUntil(this.isDestroyed$)
    ).subscribe(ruleCategories => {
      this.ruleCategories = ruleCategories;
    });
  }

  /** NOTE: when the entity changes we need to validate its compliancy against the rule server  */
  private initEntityChange() {
    /** NOTE: 2 configurations - run rules each time entity changes or run rules once onload, then user manually runs rules by clicking refresh button */
    if (environment.instantRefreshChecklist === 'true') {
      this.phxSidebarService.entityChange$().pipe(
        withLatestFrom(this.phxSidebarService.entityMetaDataChange$()),
        switchMap(([changedEntity, entityMetaData]) => this.getCompliancyRuleResults(changedEntity, entityMetaData)),
        takeUntil(this.isDestroyed$)
      ).subscribe();
    } else {
      this.phxSidebarService.entityChange$().pipe(
        withLatestFrom(this.phxSidebarService.entityMetaDataChange$()),
        switchMap(([changedEntity, entityMetaData]) => this.getCompliancyRuleResults(changedEntity, entityMetaData)),
        take(1)
      ).subscribe();
    }
  }

  /** NOTE: components can emit a refresh using the sidebar service so we need to watch it */
  private initRefreshChecklistEmit() {
    this.phxSidebarService.onRefresh.pipe(
      withLatestFrom(this.phxSidebarService.entityMetaDataChange$()),
      switchMap(([entity, entityMetaData]) => this.getCompliancyRuleResults(entity, entityMetaData)),
      takeUntil(this.isDestroyed$)
    ).subscribe();
  }

  private initRefreshComplianceDocumentRulesEmit() {
    this.phxSidebarService.onRefreshDocumentComplianceRules.pipe(
      takeUntil(this.isDestroyed$)
    ).subscribe(complianceData => {
      this.ruleGroups.forEach(group => group.rules = group.rules.filter(
        rule => !rule.additionalInformation || +rule.additionalInformation.ComplianceDocumentId !== complianceData.complianceDocumentId));
      this.applyFiltersAndSearch();
      this.cdr.detectChanges();
    });
  }

  /** NOTE: filter compliancy checklistlist as the user types a search keyword */
  private initKeyWordChange() {
    this.form.controls.keyword.valueChanges.pipe(
      takeUntil(this.isDestroyed$),
      tap(() => this.applyFiltersAndSearch()),
      debounceTime(1000),
    ).subscribe(searchVal => {
      if (searchVal) {
        this.sendGoogleClickData(`Search: ${searchVal}`);
      }
    });
  }

  /** NOTE: get rule engine compliancy for entity passed in or the current entity */
  private getCompliancyRuleResults(entity: any, entityMetaData: any = null): Observable<IRuleResult[]> {
    this.errorLoadingRules = false;
    const entityToValidate = entity || this.currentEntityValue;
    const apiUrl = this.getApiUrl(this.entityType, entityMetaData);

    return (entityToValidate ?
      this.apiService.httpPostRequest<IRuleResult[]>(`RuleEngine/RunRuleSet/${this.entityType}/Compliance`, { Entity: entityToValidate }, apiUrl, false).pipe(
        tap(() => {
          this.loadingList = false;
          this.cdr.detectChanges();
        }),
        catchError(() => {
          this.loadingList = false;
          this.errorLoadingRules = true;
          this.cdr.detectChanges();
          return of([]);
        })
      )
      :
      of([])).pipe(
        filter(ruleResult => !!ruleResult?.length),
        tap((ruleResult) => {
          this.currentEntityValue = entityToValidate;
          this.configureRules(ruleResult);
          this.loadingList = false;
          this.cdr.detectChanges();
        })
      );
  }

  private configureRules(ruleResults: IRuleResult[]) {
    this.createRuleGroups(ruleResults);
    this.calculateTotals();
    this.applyFiltersAndSearch();
  }

  /** NOTE: group the rule result into the categories pulled above in this.initEntityTypeChange */
  private createRuleGroups(ruleResults: IRuleResult[]) {
    const rulesGroupedByCategory = this.groupRulesByProperty(ruleResults, 'ruleCategory');

    let groups: IRuleGroup[] = [];

    Object.keys(rulesGroupedByCategory).forEach(ruleCategory => {
      const rules = rulesGroupedByCategory[ruleCategory] as Array<IRuleResult>;
      if (rules.some(f => f.ruleSubCategory)) {
        groups = [...groups, this.getGroupWithSubGroupRules(rules, ruleCategory)];
      } else {
        groups = [...groups, this.getGroup(rules, ruleCategory)];
      }
    });

    this.ruleGroups = groups.filter(group => !!group)?.sort((a, b) => a.categorySortOrder - b.categorySortOrder);
    /** NOTE: get the current compliancy of this entity*/
    this.currentComplianceStatus = Math.max(...this.ruleGroups.map(o => o.groupCompliance));
    this.phxSidebarService.updateEntityStatus(PhxConstants.StatusType.Types?.[this.currentComplianceStatus.valueOf()]);
  }

  private getGroupWithSubGroupRules(rules: IRuleResult[] | RuleResultWithGroups[], categoryId: string): IRuleGroup {
    let category: IRuleCategory | null = null;
    if (categoryId) {
      category = this.ruleCategories.find(f => f.id === categoryId);
    }

    const regularRules: IRuleResult[] = this.sortRules(rules.filter(f => !f.ruleSubCategory));
    const rulesWithSubGroup = rules.filter(f => f.ruleSubCategory).sort((a, b) => a.ruleSubCategoryId - b.ruleSubCategoryId);
    /** NOTE: update the subcategory name if there are multiples of the same document type 
     * RuleSubCategoryId acts as a count of documents of the same type
     */
    const mappedRulesWithSubgroup = rulesWithSubGroup.map(rule => ({
      ...rule,
      ruleSubCategory: `${rule.ruleSubCategory} ${rule.ruleSubCategoryId > 1 ? rule.ruleSubCategoryId : ''}`
    }));
    const rulesGroupedBySubCategory = this.groupRulesByProperty(mappedRulesWithSubgroup, 'ruleSubCategory');

    const nextSortOrder = regularRules[regularRules.length - 1]?.sortOrder ?? 0;
    /** NOTE: subcategory will be the document type name (ie. proof of identity)
     * - multiple documents of the same type have an added count to the name (ie. proof of identity 2, proof of identity 3)
     */
    Object.keys(rulesGroupedBySubCategory).forEach((subCategory, idx) => {
      const subgroupRules = rulesGroupedBySubCategory[subCategory] as Array<IRuleResult>;
      const tmpGroup = this.getGroup(subgroupRules, null);
      tmpGroup.category = subCategory;
      tmpGroup.isOpen = false;
      /** NOTE: get compliancy counts of the sub rules in this group that do not have sub rules */
      const docRuleWarningCount = (this.getWarningRules(tmpGroup.rules)).length;
      const docRuleNonCompliantCount = (this.getNonCompliantRules(tmpGroup.rules)).length;
      const ruleStrictness = docRuleNonCompliantCount > docRuleWarningCount ? RuleStrictness.Mandatory : RuleStrictness.Warning;

      let ruleAdditionalInformation: null | { ComplianceDocumentId: number; };
      try {
        ruleAdditionalInformation = JSON.parse(subgroupRules.find(f => f.additionalInformation)?.additionalInformation);
      } catch (e) {
        ruleAdditionalInformation = null;
      }

      /** NOTE: we need to create a rule to be the 'parent' rule for the sub rules */
      regularRules.push({
        ruleName: subCategory,
        ruleText: subCategory,
        strictness: ruleStrictness,
        sortOrder: nextSortOrder + (subgroupRules?.[0]?.sortOrder ?? 0),
        isValid: subgroupRules.every(f => f.isValid),
        additionalInformation: ruleAdditionalInformation,
        actionName: null,
        ruleCategory: subgroupRules?.[0]?.ruleSubCategory,
        ruleSubCategory: null,
        ruleSubCategoryId: idx,
        ...tmpGroup
      });
    });

    /** NOTE: get compliancy counts of the rules in this group - rules with no sub rules and the 'parent' rule that has rules */
    const compliantCount = (this.getCompliantRules(regularRules)).length;
    const warningCount = (this.getWarningRules(regularRules)).length;
    const nonCompliantCount = (this.getNonCompliantRules(regularRules)).length;
    const groupCompliance = nonCompliantCount > 0 ? ComplianceStatus.NonCompliant : (warningCount > 0 ? ComplianceStatus.Warning : ComplianceStatus.Compliant);

    return {
      category: category?.ruleCategoryText,
      length: regularRules?.length,
      rules: regularRules,
      compliantCount,
      warningCount,
      nonCompliantCount,
      groupCompliance,
      categorySortOrder: category?.sortOrder ?? 0,
      isOpen: true
    };
  }

  private getGroup(rules: Array<IRuleResult>, categoryId: string): IRuleGroup {
    const cleanRules = this.cleanDocumentComplianceRules(rules);
    const rulesSorted: IRuleResult[] = cleanRules?.length > 0 ? this.sortRules(cleanRules) : [];

    const compliantCount = (this.getCompliantRules(rulesSorted)).length;
    const warningCount = (this.getWarningRules(rulesSorted)).length;
    const nonCompliantCount = (this.getNonCompliantRules(rulesSorted)).length;

    const groupCompliance = nonCompliantCount > 0 ? ComplianceStatus.NonCompliant : (warningCount > 0 ? ComplianceStatus.Warning : ComplianceStatus.Compliant);

    let category: IRuleCategory | null = null;
    if (categoryId) {
      category = this.ruleCategories.find(f => f.id === categoryId);
    }

    return {
      category: category?.ruleCategoryText,
      length: rulesSorted?.length,
      rules: rulesSorted,
      compliantCount,
      warningCount,
      nonCompliantCount,
      groupCompliance,
      categorySortOrder: category?.sortOrder || 100,
      isOpen: true
    };
  }

  private cleanDocumentComplianceRules(rules: IRuleResult[]) {
    /** NOTE: this is only relevant to document compliance rules - they have an 'info' property */
    const rulesForReturn = [];

    rules.forEach(rule => {
      if (rule.ruleSubCategory) {
        /** NOTE: 'providedValue' is a string[] */
        /** NOTE: remove values that are placeholders and not actual FBO data */
        rule.providedValue = rule.providedValue?.filter(value => !value.includes('FBO.')).filter(value => !!value);
        /** NOTE: 'info' property is a dictionary of localized strings - get the correct one for current user  */
        rule.ruleText = rule.info[this.phxLocalizationService.currentLang] ?? rule.ruleText;

        /** NOTE: remove time from date comparison strings */
        if (rule.providedValue.length) {
          if (rule.type === PhxConstants.ComplianceComparisonType.DateComparison) {
            rule.providedValue = rule.providedValue.map(value => value?.split('T')?.[0]);
          } else {
            /** Show only distinct values, as of BUG 47662 */
            rule.providedValue = Array.from(new Set(rule.providedValue));
          }
          rulesForReturn.push(rule);
        } else if (!rule.isOptional) {
          rule.providedValue = [' - '];
          rulesForReturn.push(rule);
        }
      } else {
        rulesForReturn.push(rule);
      }
    });
    return rulesForReturn;
  }

  private sortRules(rules) {
    return rules.sort((a, b) => (a.SortOrder ?? 0) - (b.SortOrder ?? 0));
  }

  /** NOTE: group the list of rules by the property passed in */
  private groupRulesByProperty = (ruleResults: IRuleResult[], key: string) => {
    return ruleResults.filter(x => x.strictness !== RuleStrictness.NotApplicable).reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  private getNonCompliantRules(ruleResults: IRuleResult[]) {
    return ruleResults.filter(o => !o.isValid && (o.strictness === RuleStrictness.Mandatory || o.strictness === RuleStrictness.Regular));
  }

  private getWarningRules(ruleResults: IRuleResult[]) {
    return ruleResults.filter(o => !o.isValid && o.strictness === RuleStrictness.Warning);
  }

  private getCompliantRules(ruleResults: IRuleResult[]) {
    return ruleResults.filter(o => o.isValid || o.strictness === RuleStrictness.NotApplicable);
  }

  /** NOTE: calculate total counts for each rule type */
  private calculateTotals() {
    this.warningTotal = 0;
    this.compliantTotal = 0;
    this.nonCompliantTotal = 0;

    this.ruleGroups.forEach(group => {
      this.compliantTotal += group.compliantCount;
      this.nonCompliantTotal += group.nonCompliantCount;
      this.warningTotal += group.warningCount;

      this.calculateSubRuleTotals(group.rules as RuleResultWithGroups[]);
    });

    this.phxSidebarService.checklistRefreshed.emit({ nonCompliantCount: this.nonCompliantTotal });
  }

  private calculateSubRuleTotals(rules: RuleResultWithGroups[]) {
    rules.forEach(rule => {
      if (rule.rules?.length) {
        this.compliantTotal += rule.compliantCount;
        this.nonCompliantTotal += rule.nonCompliantCount;
        this.warningTotal += rule.warningCount;
      }
    });
  }
}

