import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { FboMonolithMailCampaignSelectionService } from '../../../common/data-services/fbo-monolith-mail-campaign-utility/fbo-monolith-mail-campaign-selection.service';
import { GroupedUtilities } from './models/grouped-utilities.model';
import { MAIL_CAMPAIGN_SELECTION_TYPE, MailCampaignSelectionType } from '../../../common/data-services/fbo-monolith-mail-campaign-utility/models/mail-campaign-selection-type.model';
import { MailCampaignSelectionDto } from '../../../common/data-services/fbo-monolith-mail-campaign-utility/models/mail-campaign-selection-dto';
import { ApiService } from '../../../common';
import { CommandResponse } from '../../../common/model';
import { MailCampaignSelectionCommand } from '../../../common/data-services/fbo-monolith-mail-campaign-utility/models/mail-campaign-selection-command.model';

@Injectable()
export class MailCampaignSelectionService {
  private groupedSelections$ = new BehaviorSubject<GroupedUtilities | null>(null);

  constructor(private fboMonolithMailCampaignSelectionService: FboMonolithMailCampaignSelectionService, private apiService: ApiService) {}

  public get templates$() {
    return this.getFilteredSelections(MAIL_CAMPAIGN_SELECTION_TYPE.Template);
  }

  public get filters$() {
    return this.getFilteredSelections(MAIL_CAMPAIGN_SELECTION_TYPE.Filter);
  }

  public loadSelections(): void {
    this.fboMonolithMailCampaignSelectionService.getSelectionsList().subscribe(({ Items }) => {
      const groupedUtilities = Items.reduce<GroupedUtilities>(
        (groupedUtils, utility) => {
          groupedUtils[utility.MailCampaignSelectionTypeId].push(utility);
          return groupedUtils;
        },
        {
          [MAIL_CAMPAIGN_SELECTION_TYPE.Filter]: [],
          [MAIL_CAMPAIGN_SELECTION_TYPE.Template]: []
        }
      );
      this.groupedSelections$.next(groupedUtilities);
    });
  }

  public saveSelection(mailCampaignSelectionCommand: MailCampaignSelectionCommand): Promise<CommandResponse> {
    return this.apiService.command('MailCampaignSelectionSave', mailCampaignSelectionCommand).then(value => {
      return value;
    });
  }

  private getFilteredSelections(utilityType: MailCampaignSelectionType): Observable<MailCampaignSelectionDto[]> {
    return this.groupedSelections$.pipe(
      filter(utilities => Boolean(utilities)),
      map(utilities => utilities[utilityType])
    );
  }
}
